<template>
  <div :id="editorId"></div>
</template>

<script>
import * as monaco from 'monaco-editor'
export default {
  name: 'MonacoEditor',
  props: ['editorId', 'value', 'language', 'readOnly'],
  data() {
    return {
      editor: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.editor = monaco.editor.create(document.getElementById(this.editorId), {
        value: this.value, //编辑器初始显示文字
        language: this.language,
        scrollBeyondLastLine: false, //不显示滚动
        readOnly: this.readOnly, //只读
        minimap: { enabled: false } //关闭右上角小地图
      })
      this.editor.onDidChangeModelContent(event => {
        this.$emit('input', this.editor.getValue())
      })
    })
  }
}
</script>

<style lang="less" scoped></style>
